<template>
  <v-data-table :headers="headers" :items="htcLots" :loading="loading" dense>
    <template v-slot:top> </template>
    <template v-slot:item.expiryDate="{ item }">{{
      item.expiryDate | moment("YYYY-MM-DD")
    }}</template>
    <template v-slot:item.lotDetails.isSelected="{ item }">
      <v-checkbox
        v-model="item.lotDetails.isSelected"
        hide-details=""
      ></v-checkbox>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    loading: {
      type: [Boolean, String],
      default: null,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("htc.lotnumberHeaders.productName"),
          value: "product.productName",
          class: "grey white--text",
        },
        {
          text: this.$t("htc.lotnumberHeaders.lNumber"),
          value: "lNumber",
          class: "grey white--text",
        },
        {
          text: this.$t("htc.lotnumberHeaders.expiryDate"),
          value: "expiryDate",
          class: "grey white--text",
        },
        {
          text: this.$t("htc.lotnumberHeaders.isSelected"),
          value: "lotDetails.isSelected",
          class: "grey white--text",
        },
      ],
      htcLots: [],
      showAll: false,
    };
  },
  computed: {},
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.htcLots = v;
      },
    },
  },
  methods: {},
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex py-1"},[_c('v-spacer'),_c('v-btn',{staticClass:"green--text",attrs:{"text":"","tile":""},on:{"click":function($event){return _vm.openUserDetail(-1)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("actions.addUser"))+" ")],1)],1),_c('v-data-table',{key:this.$store.state.userKey,attrs:{"dense":"","headers":_vm.headers,"items":_vm.users,"sort-by":['firstName'],"loading":_vm.loading,"loading-text":_vm.$t('app.loading'),"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1],
    },"items-per-page":this.$store.state.itemsPerPage},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openUserDetail(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.nmoCountryCode",fn:function(ref){
    var item = ref.item;
return [(item.nmoCountryCode != null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("country." + item.nmoCountryCode))+" ")]):_vm._e()]}},{key:"item.share",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":"","disabled":""},model:{value:(item.share),callback:function ($$v) {_vm.$set(item, "share", $$v)},expression:"item.share"}})]}},{key:"item.creationDate",fn:function(ref){
    var item = ref.item;
return [(item.creationDate != null)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.creationDate,"YYYY-MM-DD HH:mm:ss")))]):_vm._e()]}},{key:"item.activationDate",fn:function(ref){
    var item = ref.item;
return [(item.activationDate != null)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.activationDate,"YYYY-MM-DD HH:mm:ss")))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form ref="form" v-model="isValid">
      <v-card-text class="px-0 pb-1">
        <v-select
          class="pb-2"
          dense
          hide-details=""
          :disabled="this.parameter.nmoId != -1"
          :items="nmos"
          :label="getTranslation('htc.creationForm.formFieldTitles.nmo', '*')"
          required
          v-model="htc.nmoId"
          item-text="name"
          item-value="id"
          :rules="[
            () => !!htc.nmoId || $t('htc.creationForm.errors.nmoRequired'),
          ]"
          outlined
        ></v-select>
        <v-text-field
          class="pb-2"
          dense
          hide-details=""
          ref="htcNameField"
          :label="getTranslation('htc.creationForm.formFieldTitles.name', '*')"
          required
          :rules="[
            () => !!htc.name || $t('htc.creationForm.errors.nameRequired'),
            () => !isHtcNameTaken || $t('htc.creationForm.errors.nameTaken'),
          ]"
          @keydown="validateHtcNameUniqueness()"
          @paste="validateHtcNameUniqueness()"
          v-model="htc.name"
          outlined
        ></v-text-field>
      </v-card-text>
      <div class="pt-0 pb-3 d-flex">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="my-2"
          @click="save()"
          :disabled="isSending || !isValid"
        >
          {{ $t("actions.save") }}
        </v-btn>
        <v-progress-circular
          v-if="isSending"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-if="isEdit">
        <v-card-title class="pa-0">
          <v-tabs v-model="currentTab" height="30px" active-class="active-tab">
            <v-tab class="tab" key="users">Users</v-tab>
            <!-- <v-tab class="tab" key="lots">Lots</v-tab> -->
          </v-tabs>
        </v-card-title>
        <v-card-text class="pa-0" style="border: 1px solid lightgray">
          <v-tabs-items v-model="currentTab">
            <v-tab-item key="users">
              <user-tab
                :value="users"
                :loading="isDataUserLoading"
                @click:select="openUserDetail"
              ></user-tab>
            </v-tab-item>
            <v-tab-item key="lots">
              <lot-number-tab
                v-model="htcLots"
                :loading="htcLotsLoading"
              ></lot-number-tab>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </template>
    </v-form>
    <v-snackbar
      class="pt-10"
      v-model="showSnackbar"
      top
      :color="showSnackbarColor"
      :timeout="3000"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import UserTab from "./htcDetail/UserTab.vue";
import LotNumberTab from "./htcDetail/LotNumberTab.vue";
export default {
  props: ["parameter"],
  components: { LotNumberTab, UserTab },
  data() {
    return {
      currentTab: "users",
      isValid: false,
      isDataUserLoading: false,
      isSending: false,
      isShowContent: false,
      isHtcNameTaken: false,
      htcNameTimeout: null,
      showSnackbar: false,
      snackBarMessage: "Htc saved successfully",
      showSnackbarColor: "success",
      nmos: [],
      htc: {},
      htcLots: [],
      htcLotsLoading: false,
      users: [],
    };
  },

  computed: {
    isEdit() {
      return this.parameter.id > 0;
    },
  },
  methods: {
    onSetTitle(title) {
      this.$emit("onSetTitle", title);
    },
    close() {
      this.$store.commit("hideModal");
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
    insert() {
      this.isSending = true;
      var xhr = axios.post(
        this.$store.getters.getEnvironment + "htc/",
        this.htc
      );

      return xhr
        .then((response) => {
          this.htc.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setHTCKey");
          //this.close();
        })
        .catch((e) => {});
    },
    load() {
      this.isDataLoading = true;
      var xhr = axios.get(
        this.$store.getters.getEnvironment + "htc/" + this.parameter.id
      );
      return xhr
        .then((response) => {
          this.htc = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadLotsForHTC() {
      this.htcLotsLoading = true;
      axios
        .get(
          this.$store.getters.getEnvironment + "lotNumber/htc/" + this.htc.id
        )
        .then((res) => {
          this.htcLots = res.data;
        })
        .catch((E) => {})
        .finally(() => {
          this.htcLotsLoading = false;
        });
    },
    loadNMO() {
      this.isDataLoading = true;
      axios
        .get(this.$store.getters.getEnvironment + "nmo?nameOnly=true")
        .then((response) => {
          this.nmos = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadUsers() {
      this.isDataUserLoading = this.$store.state.loadingBarColor;
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}user?htcId=${this.htc.id}`
      );
      return xhr
        .then((res) => {
          this.isDataUserLoading = false;
          this.users = res.data;
        })
        .catch((e) => {});
    },
    openUserDetail(value) {
      this.$store.commit("showModal", {
        componentName: "UserDetail",
        parameter: {
          id: value,
          nmoId: this.htc.nmoId,
          htcId: this.htc.id,
          origin: "htcDetail",
        },
      });
    },
    save() {
      if (this.htc.id == null) this.insert();
      else {
        this.update();
        this.updateLots();
      }
    },

    update() {
      this.isSending = true;
      return axios
        .put(
          this.$store.getters.getEnvironment + "htc/" + this.htc.id,
          this.htc
        )
        .then((response) => {
          this.htc.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setHTCKey");
          //this.close();
        })
        .catch((e) => {});
    },
    updateLots() {
      var body = this.htcLots.map((x) => {
        return {
          lotNumberCountryId: x.lotDetails.lotNumberCountryId,
          isSelected: x.lotDetails.isSelected,
        };
      });

      return axios
        .put(
          this.$store.getters.getEnvironment + "lotNumber/htc/" + this.htc.id,
          body
        )
        .then((res) => {
          this.showSnackbar = true;
        });
    },
    validateHtcNameUniqueness() {
      if (this.htcNameTimeout != null) {
        clearTimeout(this.htcNameTimeout);
      }
      this.htcNameTimeout = setTimeout(() => {
        var xhr = axios.get(
          `${this.$store.getters.getEnvironment}htc/count?nmoId=${this.htc.nmoId}&name=${this.htc.name}&exact=true`
        );
        xhr
          .then((res) => {
            this.isHtcNameTaken = res.data.htcCount > 0;
            this.$refs["htcNameField"].validate();
          })
          .catch((e) => {});
      }, 500);
    },
  },
  async created() {
    this.loadNMO();
    if (this.isEdit) this.onSetTitle(this.$t("htc.view.edit"));
    else this.onSetTitle(this.$t("htc.view.create"));
    if (this.parameter.id != -1) {
      await this.load();
      this.loadUsers();
    }
    if (this.parameter.nmoId != -1) this.htc.nmoId = this.parameter.nmoId;
    this.loadLotsForHTC();
  },
};
</script>

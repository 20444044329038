<template>
  <div>
    <div class="d-flex py-1">
      <v-spacer></v-spacer>
      <v-btn text tile class="green--text" @click="openUserDetail(-1)">
        <v-icon>mdi-plus</v-icon>
        {{ $t("actions.addUser") }}
      </v-btn>
    </div>

    <v-data-table
      dense
      :headers="headers"
      :key="this.$store.state.userKey"
      :items="users"
      :sort-by="['firstName']"
      :loading="loading"
      :loading-text="$t('app.loading')"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1],
      }"
      :items-per-page="this.$store.state.itemsPerPage"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="openUserDetail(item.id)" icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.nmoCountryCode="{ item }">
        <span v-if="item.nmoCountryCode != null">
          {{ $t("country." + item.nmoCountryCode) }}
        </span>
      </template>
      <template v-slot:item.share="{ item }">
        <v-checkbox v-model="item.share" readonly disabled></v-checkbox>
      </template>
      <template v-slot:item.creationDate="{ item }">
        <span v-if="item.creationDate != null">{{
          item.creationDate | moment("YYYY-MM-DD HH:mm:ss")
        }}</span>
      </template>
      <template v-slot:item.activationDate="{ item }">
        <span v-if="item.activationDate != null">{{
          item.activationDate | moment("YYYY-MM-DD HH:mm:ss")
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: [Boolean, String],
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("htc.headerRow.action"),
          value: "action",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.firstName"),
          value: "firstName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.lastName"),
          value: "lastName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.email"),
          value: "email",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.phoneNumber"),
          value: "phoneNumber",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.type"),
          value: "userTypeName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.nmoName"),
          value: "nmoName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.nmoCountry"),
          value: "nmoCountryCode",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.htcName"),
          value: "htcName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.creationDate"),
          value: "creationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.activationDate"),
          value: "activationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.notes"),
          value: "notes",
          class: "grey white--text",
        },
      ],
      users: [],
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.users = v;
      },
    },
  },
  methods: {
    openUserDetail(value) {
      this.$emit("click:select", value);
    },
  },
};
</script>